exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-api-documentation-js": () => import("./../../../src/pages/api-documentation.js" /* webpackChunkName: "component---src-pages-api-documentation-js" */),
  "component---src-pages-blognew-js": () => import("./../../../src/pages/blognew.js" /* webpackChunkName: "component---src-pages-blognew-js" */),
  "component---src-pages-certified-mail-js": () => import("./../../../src/pages/certified-mail.js" /* webpackChunkName: "component---src-pages-certified-mail-js" */),
  "component---src-pages-check-mail-js": () => import("./../../../src/pages/check-mail.js" /* webpackChunkName: "component---src-pages-check-mail-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-first-class-mail-js": () => import("./../../../src/pages/first-class-mail.js" /* webpackChunkName: "component---src-pages-first-class-mail-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-and-insights-js": () => import("./../../../src/pages/news-and-insights.js" /* webpackChunkName: "component---src-pages-news-and-insights-js" */),
  "component---src-pages-priority-mail-js": () => import("./../../../src/pages/priority-mail.js" /* webpackChunkName: "component---src-pages-priority-mail-js" */),
  "component---src-templates-industry-item-jsx": () => import("./../../../src/templates/IndustryItem.jsx" /* webpackChunkName: "component---src-templates-industry-item-jsx" */)
}

